<template>
  <div class="warelist" :class="`warelist--${props.type ?? 'standard'}`" ref="wareList" data-pagearea="wareList">
    <div v-if="props.withSorting" class="min-h-[2rem] mb-4">
      <ClientOnly>
        <div v-if="props.withSorting" class="flex flex-wrap gap-x-10 gap-y-2 items-center">
          <div class="warelist__sort" :class="{ uppercase: !sortMenuOpen }">
            <button v-for="item in sortOptions" @click="sort(item.key)" class="warelist__sort__item" :class="{ 'warelist__sort__item--selected': currentSort === item.key }">
              {{ $t(item.label) }}
            </button>
          </div>
          <span class="uppercase">{{ $t('n produktů', props.totalFullItems) }}</span>
          <AtomsTextLink :to="cache.getPageUrl(33894)" target="blank" class="font-bold underline text-neutral-400 tracking-normal">{{ $t('Podle čeho řadíme produkty?') }}</AtomsTextLink>
        </div>
      </ClientOnly>
    </div>
    <div v-if="props.loading" class="warelist__loader">
      <div class="sticky top-[400px] desktop:top-[500px]">
        <AtomsGlobalLoader />
      </div>
    </div>
    <div class="warelist__items" ref="wareListItems">
      <template v-if="props.type === 'slider' || props.type === 'recent' || props.type === 'light' || props.type === 'article'">
        <Carousel :breakpoints="getCarouselBreakpoints(props.type)" :itemsToShow="1" :itemsToScroll="1">
          <Slide v-for="item in props.items" :key="item.id">
            <MoleculesBlocksWareListItem :type="props.type" :item="item" :link-target="props.itemsLinkTarget" :list-id="props.groupId" :list-name="props.listName" :element-name-override="props.itemNameElementOverride" />
          </Slide>
          <template #addons>
            <Navigation v-if="props.withSliderNavigation" />
            <Pagination v-if="props.withSliderPagination && props.items.length > 1" />
          </template>
        </Carousel>
      </template>
      <template v-else>
        <template v-for="item in props.items" :key="item.id">
          <MoleculesBlocksWareListPromotionItem v-if="props.promotions && item.index % props.pageSize === 5 && getPromotionItem(item.index)" :id="getPromotionItem(item.index).id" :style-id="getPromotionItem(item.index).idStyle" :description="getPromotionItem(item.index).description" :title="getPromotionItem(item.index).title" :url="getPromotionItem(item.index).url" />
          <MoleculesBlocksWareListItem v-if="(item.index + 1) % props.pageSize === 0 && getPromotionItem(item.index) ? false : true" :type="props.type" :item="item" :link-target="props.itemsLinkTarget" :list-id="props.groupId" :list-name="props.listName" :element-name-override="props.itemNameElementOverride" />
        </template>
      </template>
    </div>
    <OrganismsListsPaging :key="props.page" v-if="props.pageSize" class="mt-6" :shown-items="shownItems" :total-pages="totalPages" :current-page="props.page" :total-items="props.totalItems" :auto-scroll-element="wareList"></OrganismsListsPaging>
  </div>
</template>
<script setup>

import { Carousel, Slide, Navigation, Pagination } from '~/components/molecules/containers/carousel.js';
import "assets/css/carousel.css";

const props = defineProps({
  type: String,
  items: Object,
  promotions: Array,
  page: Number,
  pageSize: Number,
  totalItems: Number,
  totalFullItems: Number,
  withSorting: Boolean,
  withSliderNavigation: Boolean,
  withSliderPagination: Boolean,
  itemsLinkTarget: String,
  groupId: Number,
  listName: String,
  filter: String,
  appending: Boolean,
  loading: Boolean,
  itemNameElementOverride: String
});

const appConfig = useAppConfig();
const shopRouting = useShopRouting();
const route = useRoute();
const analytics = useBaseAnalytics();
const wareAnalytics = useWareAnalytics();
const i18n = useI18n();
const cache = useCache();
const utils = useUtils();

const wareList = ref(null);
const wareListItems = ref(null);

const shownItems = computed(() => Math.min(props.page * props.pageSize, props.totalItems));

const totalPages = computed(() => {
  let numOfPages = props.pageSize > 0 ? Math.ceil(props.totalItems / props.pageSize) : 0;
  let result = numOfPages;

  if (numOfPages && props.promotions?.length) {
    result = Math.ceil((props.totalItems + numOfPages) / props.pageSize);
  }

  return result;
});

watch(() => props.items, async (newValue, oldValue) => {

  if (process.client && props.items && props.listName && !utils.isDeepEqual(newValue, oldValue)) {

    wareAnalytics.getItems(props.items.map(item => item.id)).then(data => {

      // call view_item_list separately in blocks of approx. 10 items, otherwise the payload will be too large in some cases

      for (let i = 0; i < Math.ceil(data.length / 10); i++) {
        analytics.pushEvent('view_item_list', {
          ecommerce: {
            item_list_id: props.groupId,
            item_list_name: props.listName,
            item_list_page: props.page,
            item_list_filter: props.filter,
            items: data.slice(i * 10, (i * 10) + 10)
          }
        });
      }

    });

  }
}, { immediate: true });

const getPromotionItem = (itemIndex) => {
  let result = null;

  if (props.promotions?.length) {
    const index = Math.floor(itemIndex / props.pageSize) % props.promotions.length;
    result = props.promotions[index];
  }

  return result;
};

onMounted(() => {
  watch(() => props.loading, () => {
    if (props.loading) {
      wareListItems.value.classList.add('opacity-20');
    }
    else {
      wareListItems.value.classList.remove('opacity-20');
    }
  });

  watch(() => props.appending, () => {
    if (props.appending) {

      const previousBottom = wareListItems.value.getBoundingClientRect().bottom + window.scrollY;

      wareListItems.value.style.maxHeight = `${wareListItems.value.scrollHeight}px`;
      wareListItems.value.style.overflow = 'hidden';
      wareListItems.value.style.maxHeight = `${wareListItems.value.scrollHeight + 2000}px`;

      //window.scrollTo({ top: previousBottom, behavior: 'smooth' });
      useUtils().scrollTo(previousBottom);

      setTimeout(() => {
        wareListItems.value.style.maxHeight = 'initial';
        wareListItems.value.style.overflow = 'visible';
      }, 300);
    }
  });
});

const getCarouselBreakpoints = type => {
  switch (type) {
    case 'article':
      return {
        0: {
          itemsToShow: 1,
          snapAlign: "start",
        },
        768: {
          itemsToShow: 2,
          snapAlign: "start",
        },
      };
      break;
    case 'recent':
      return {
        0: {
          itemsToShow: 3,
          snapAlign: "start",
        },
        768: {
          itemsToShow: 6,
          snapAlign: "start",
        },
        1440: {
          itemsToShow: 8,
          snapAlign: "start",
        },
      };
      break;
    case 'light':
      return {
        0: {
          itemsToShow: 1,
          snapAlign: "start",
        },
        768: {
          itemsToShow: 2,
          snapAlign: "start",
        },
        1440: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      };
      break;
    default:
      return {
        768: {
          itemsToShow: 2,
          snapAlign: "start",
        },
        1440: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      };
      break;
  };
}

const sortOptions = [
  { key: "default", label: "Nejprodávanější" },
  { key: "newest", label: "Nejnovější" },
  { key: "cheapest", label: "Nejlevnější" },
  { key: "most-expensive", label: "Nejdražší" },
];

const sortMenuOpen = ref(false);
const currentSort = ref("default");

watch(route, async () => {

  const newSort = shopRouting.parseHash().find(q => q.key === 'sort')?.value[0];

  if (newSort && currentSort.value != newSort) {
    currentSort.value = newSort;
  }

}, { flush: 'pre', immediate: route.hash, deep: true });

const sort = (key) => {
  currentSort.value = key;

  // reset page to 1
  const modifiedHash = shopRouting.stringifyHash(shopRouting.updateValueInHash('p', '', false, true));

  // update sorting value in hash
  const hashObj = shopRouting.updateValueInHash("sort", key, false, true, modifiedHash);

  // navigate to the modified hash
  shopRouting.pushHash(hashObj);

};

</script>
<style lang="postcss">
.warelist {

  @apply relative;

  &__sort {
    @apply flex flex-wrap gap-1;

    &__item {
      @apply px-5 py-2 uppercase text-xs tracking-wide bg-neutral-100 transition-colors;

      &--selected {
        @apply bg-neutral-500 text-neutral-50;
      }
    }
  }

  &--standard {
    & .warelist__items {
      @apply grid grid-cols-1 -ml-2 -mr-2 tablet:grid-cols-3 desktop:grid-cols-4 transition-all duration-300 ease-in-out;
    }
  }

  &--slider {
    @apply mx-2;

    & .warelist__items {
      @apply -mx-4;
    }

    & .warelist__item {
      @apply my-4 desktop:my-14;
    }
  }

  &--article {
    @apply mx-2;

    & .warelist__items {
      @apply -mx-4;
    }
  }

  &--recent {
    @apply flex-1;

    & .warelist__item {
      @apply flex items-center justify-center my-6 aspect-square bg-white;
    }
  }

  &--search {
    & .warelist__items {
      @apply block;
    }
  }

  &--light {
    & .warelist__items {
      @apply -mx-2;
    }

    & .warelist__item__info {
      @apply justify-start my-0;
    }

    & .warelist__item {
      &__name {
        @apply mb-4 pr-4 font-bold text-base tracking-normal tablet:text-xl;
      }
    }
  }

  &--light-single {

    & .warelist__item {

      @apply justify-center;

      &>div {
        @apply !border-0;
      }

      &__info {
        @apply justify-start my-0;
      }
      
      &__name {
        @apply font-bold text-xl tracking-normal mb-4;
      }
    }
  }

  &__loader {
    @apply absolute left-0 right-0 top-0 bottom-0 z-[2] flex items-start justify-center pt-[200px] pb-[200px] text-[3rem];
  }
}
</style>
