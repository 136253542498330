<template>
  <AtomsTextLink class="warelist__promo-item" :class="{ 'warelist__promo-item--with-hover': props.styleId !== 8 }" :to="props.url" :style="`background-image: url('${useShopZone().value.staticResourcesRoot}/shopFiles/promos/${props.id}.jpg');`" :data-promotioncampaign="utils.cleanHtmlAttribute(props.description)" data-promotiontype="wareList" :data-promotionname="utils.cleanHtmlAttribute(props.title)">

    <!-- title -->

    <div v-if="props.styleId !== 8" class="warelist__promo-item__title" :class="`warelist__promo-item__title--${props.styleId}`">{{ props.title }}</div>

    <!-- button -->

    <MoleculesButtonsButton v-if="props.styleId !== 8" :dummy="true" :ghost="props.styleId === 2" :secondary="props.styleId === 3" :black="props.styleId === 4" :white-ghost="props.styleId === 5" :secondary-ghost="props.styleId === 6">
      {{ props.description }}
    </MoleculesButtonsButton>

  </AtomsTextLink>
</template>
<script setup>

const props = defineProps({
  id: Number,
  styleId: Number,
  description: String,
  title: String,
  url: String
});

const appConfig = useAppConfig();
const utils = useUtils();

</script>
<style lang="postcss">
.warelist__promo-item {
  @apply aspect-square relative overflow-hidden flex flex-col justify-end gap-6 m-2 p-6 tracking-normal bg-center bg-cover transition-all ease-in-out tablet:aspect-auto desktop:p-8;

  &--with-hover {

    &::before {
      @apply content-[''] absolute left-0 top-0 right-0 bottom-0 bg-cover bg-center transition-all duration-500 ease-in-out z-[1];
      background-image: inherit;
      filter: grayscale(25%) brightness(30%) saturate(100%);
      z-index: 1;
    }

    &::after {
      @apply content-[''] absolute left-0 top-0 right-0 bottom-0 bg-cover bg-center z-[2] transition-all duration-500 ease-in-out;
      background-image: inherit;
      filter: grayscale(25%) brightness(50%) saturate(50%);
      z-index: 1;
    }

    &:hover::after {
      opacity: 0;
    }

    &:hover::before {
      @apply scale-[1.02];
    }
  }

  &>* {
    position: relative;
    z-index: 3;
  }

  &__title {
    @apply text-2xl font-bold text-neutral-50;

    &::before {
      @apply block w-16 mb-4 border-t-2 content-[''];
    }

    &--1 {
      &::before {
        @apply border-primary-500;
      }
    }

    &--2 {
      &::before {
        @apply border-primary-500;
      }
    }

    &--3 {
      &::before {
        @apply border-secondary-500;
      }
    }

    &--4 {
      &::before {
        @apply border-neutral-500;
      }
    }

    &--5 {
      &::before {
        @apply border-neutral-50;
      }
    }

    &--6 {
      &::before {
        @apply border-secondary-500;
      }
    }
  }

  &:hover {
    @apply no-underline shadow-xl;
  }
}
</style>