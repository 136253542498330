<template>
  <div class="badges">
    <template v-for="item in badges.sort((a, b) => a.position - b.position)">
      <div v-if="item.isTextBadge" class="badges__text" :style="getStyleForBadge(item)">{{ item.text }}</div>
      <AtomsTextLink v-if="item.isLinkBadge" class="badges__text" :to="item.href">{{ item.text }}</AtomsTextLink>
      <AtomsImagesImage v-if="item.isImageBadge" class="badges__image" :src="item.imageUrl" :alt="item.text" :title="item.text" />
    </template>
  </div>
</template>
<script setup>

// DOCS: https://www.notion.so/ba128fd0e66f4d5caf2b6adaea6bcd0b?v=f646cd52e4cd4e08914e5c816300fb48&p=b1652cfaa2e54cb0ba4c962dc50141cc&pm=s

const props = defineProps({
  wareItem: Object,
  enableLinksOnTextBadges: Boolean,
  freeShippingVisible: Boolean,
  forWareDetail: Boolean,
});

const i18n = useI18n();
const appConfig = useAppConfig();

let badges = useWareBadges().getBadgesForWare(props.wareItem, i18n, props.forWareDetail);

const emit = defineEmits(['badgesLoaded']);

badges = badges.filter((badge) => {
  if (badge.type === 'FREE_SHIPPING' && !props.freeShippingVisible) {
    return false;
  }

  return true;
}).map((badge) => {
  if (badge.isLinkBadge && !props.enableLinksOnTextBadges) {
    badge.isTextBadge = true;
    badge.isLinkBadge = false;
  }

  return badge;
});


const customLabelStyles = {
  [appConfig.parameterlValues.internal_engraving]: 'border-color: #a99146; color: #a99146;',
  [appConfig.parameterlValues.label_sale]: 'border-color: #de1d20; color: #de1d20;',
  [appConfig.parameterlValues.clearance]: 'border-color: #ff250d; color: #ff250d;',
  [appConfig.parameterlValues.tip]: 'border-color: #e5e5e5; color: #2db34e',
  [appConfig.parameterlValues.label_news]: 'border-color: #81613c; color: #81613c;',
  [appConfig.parameterlValues.label_blackFriday]: 'border-color: #de1d20; color: #de1d20;',
  999999: 'border-color: #e7c407; color: #e7c407;',
};

const getStyleForBadge = (badge) => {
  return badge.style ?? customLabelStyles[badge.lValue];
}

emit('badgesLoaded', badges);

</script>
<style lang="postcss">
.badges {
  &__text {
    @apply inline-block mr-[0.5em] mb-[0.5em] px-[1em] py-[0.25em] border-2 border-neutral-400 align-middle font-bold tracking-normal uppercase text-neutral-400;
  }

  &__image {
    @apply inline-block mr-[0.5em] mb-[0.5em] h-[2.7em] align-middle;
  }
}
</style>